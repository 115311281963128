import '@resources/css/app.css';
import lodash from 'lodash';
import axios from 'axios';
import * as luxon from 'luxon';
import getErrorMessage from '@web/utils/getErrorMessage.js';
import hasErrorMessage from '@web/utils/hasErrorMessage.js';
import Locker from '@web/utils/Locker.js';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import ThirdPartyServices from '@web/utils/ThirdPartyServices.js';
import { reactive } from 'vue';
import VueToastification from 'vue-toastification';
import eventBus from '@web/utils/eventBus.js';
import { handleIncomingAssetsVersionFromAxiosResponse } from '@web/utils/assetsVersion.ts';

import FormControl from '@web/directives/FormControl.js';
import FocusableError from '@web/directives/FocusableError.js';
import ClickOutside from '@web/directives/ClickOutside.js';
import AutoSize from '@web/directives/AutoSize.js';
import Resize from '@web/directives/Resize.js';
import Intersect from '@web/directives/Intersect.js';
import Tippy from '@web/directives/Tippy.js';
import Focus from '@web/directives/Focus.js';
import PreventAutocomplete from '@web/directives/PreventAutocomplete.js';
import formatNumber from '@web/utils/formatNumber.ts';
import formatDuration from '@web/utils/formatDuration.ts';
import pluralize from '@web/utils/pluralize.ts';
import timeago from '@web/utils/timeago.ts';
import truncate from '@web/utils/truncate.js';
import formatPhoneNumber from '@web/utils/formatPhoneNumber.ts';
import formatDateTime from '@web/utils/formatDateTime.ts';

// This polyfill provides an ability to use the smooth scrolling
// in browsers where it's not supported natively.
import 'scroll-behavior-polyfill';

window.axios = axios;
window._ = lodash;
window.luxon = luxon; // todo: remove
window.locker = new Locker();
window.Bus = eventBus;

// Locker's expired entries must be flushed automatically because
// localStorage (that is used under the hood) is limited to about 5MB.
// However, we don't want to do that on every page load, that's why
// we use the "lottery".
if (_.random(1, 100) <= 10) {
  locker.flushExpired();
}

window.axios.interceptors.response.use(
  (response) => {
    handleIncomingAssetsVersionFromAxiosResponse(response);

    return response;
  },
  (error) => {
    // If the `response` property does not exist on the `error` object,
    // it means we have faced a "Network error".
    // In that case, we won't do anything in the interceptor, and
    // will just let the component handle displaying an error,
    // if necessary, depending on the circumstance.
    // Reference: https://github.com/axios/axios/issues/383#issuecomment-316501886
    if (error.response) {
      handleIncomingAssetsVersionFromAxiosResponse(error.response);

      const statusCode = error.response.status;
      const errorType = error.response?.data?.error?.type;

      if (statusCode === 401) {
        if (['invalid_token'].includes(errorType)) {
          window.Bus.$emit('invalidToken');
        } else {
          window.Bus.$emit('sessionExpired');
        }
      }

      if (statusCode === 403) {
        if (['invalid_account', 'invalid_account_status'].includes(errorType)) {
          window.Bus.$emit('invalidAccount');
        }

        if (['invalid_user'].includes(errorType)) {
          window.Bus.$emit('invalidUser');
        }
      }
    }

    error.getErrorMessage = (fallback) => getErrorMessage(error, fallback);
    error.hasErrorMessage = () => hasErrorMessage(error);

    return Promise.reject(error);
  },
);

luxon.Settings.throwOnInvalid = true;

// Axios
window.axios.defaults.headers.common = {
  Accept: 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
};

// Bugsnag
Bugsnag.start({
  apiKey: window.Clearstream.global.keys.bugsnag,
  plugins: [new BugsnagPluginVue()],
  releaseStage: window.Clearstream.global.env ?? 'unknown',
  appVersion: window.Clearstream.global.appVersion,
  user: window.Clearstream.user
    ? {
        id: String(window.Clearstream.user.id),
        email: window.Clearstream.user.email,
        name: window.Clearstream.user.name,
      }
    : null,
  metadata: {
    account: window.Clearstream.account
      ? {
          id: String(window.Clearstream.account.id),
          name: window.Clearstream.account.name,
          role: window.Clearstream.account.user.role,
        }
      : null,
  },
  onError: function ({ originalError }) {
    if (
      originalError?.code === 'ERR_BAD_REQUEST' &&
      originalError?.response?.status === 401
    ) {
      return false;
    }
  },
});

window.bugsnagClient = Bugsnag;

ThirdPartyServices.init();

// Luxon
if (window.Clearstream?.user?.timezone) {
  luxon.Settings.defaultZone = window.Clearstream.user.timezone;
}

const ticker = reactive({ now: new Date() });
setInterval(() => {
  ticker.now = new Date();
}, 5000);

function bootVueApp(vueApp) {
  vueApp.use(Bugsnag.getPlugin('vue'));
  vueApp.use(VueToastification, {
    toastClassName: 'clearstream-toast',
    maxToasts: 5,
    draggable: false,
    newestOnTop: false,
    closeButton: false,
    closeOnClick: true,
    icon: false,
    transition: 'toast-fade',
    transitionDuration: 200,
    pauseOnHover: false,
    hideProgressBar: true,
    shareAppContext: true,
  });

  vueApp.directive('form-control', FormControl);
  vueApp.directive('focusable-error', FocusableError);
  vueApp.directive('click-outside', ClickOutside);
  vueApp.directive('autosize', AutoSize);
  vueApp.directive('resize', Resize);
  vueApp.directive('intersect', Intersect);
  vueApp.directive('tippy', Tippy);
  vueApp.directive('focus', Focus);
  vueApp.directive('prevent-autocomplete', PreventAutocomplete);

  vueApp.config.globalProperties.$luxon = luxon;
  vueApp.config.globalProperties.$formatNumber = formatNumber;
  vueApp.config.globalProperties.$formatDuration = formatDuration;
  vueApp.config.globalProperties.$pluralize = pluralize;
  vueApp.config.globalProperties.$timeago = timeago;
  vueApp.config.globalProperties.$truncate = truncate;
  vueApp.config.globalProperties.$formatPhoneNumber = formatPhoneNumber;
  vueApp.config.globalProperties.$formatDateTime = formatDateTime;
  vueApp.config.globalProperties.$ticker = ticker;
  vueApp.config.globalProperties.$bus = eventBus;
  vueApp.config.globalProperties.window = window;
}

export { bootVueApp };
